import React from 'react';

const IcEdit: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9999 0.992188L19.0087 5.00107L6.57728 17.4325L0.964844 19.0361L2.5684 13.4236L14.9999 0.992188ZM3.68132 14.0785L2.78487 17.2161L5.92243 16.3196L17.241 5.00107L14.9999 2.75995L3.68132 14.0785Z"
      fill="#1F1F1F"
      fillOpacity="0.75"
    />
  </svg>
);

export default IcEdit;
