import styled from 'styled-components';

export const StyledUserBookListWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.white};
`;

export const StyledLinkWrapper = styled.div`
  padding: ${({ theme }) => theme.space[4]}px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
`;
