import React from 'react';

const IcCheck: React.FC = () => (
  <svg id="Group_16408" data-name="Group 16408" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <rect id="Rectangle_6074" data-name="Rectangle 6074" width="24" height="24" fill="none" />
    <path
      id="Path_8602"
      data-name="Path 8602"
      d="M5,13l4,4L19,7"
      fill="none"
      stroke="#39812c"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IcCheck;
