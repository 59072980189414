import styled from 'styled-components';

export const StyledListItemWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  padding: ${({ theme }) => `${theme.space[5]}px 0 ${theme.space[4]}px`};
  margin: 0 ${({ theme }) => theme.space[4]}px;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  &:last-child {
    border-bottom: none;
  }
`;

export const StyledDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-inline-end: ${({ theme }) => theme.space[4]}px;
`;

export const StyledTitle = styled.span`
  color: ${({ disabled, theme }) => (disabled ? theme.color.greyShade : theme.color.headlineText)};
`;

export const DetailsSpan = styled.span`
  color: ${({ disabled, theme }) => (disabled ? theme.color.grey5 : theme.color.grey0)};
`;
