import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '@zydalabs/zac-react';
import { StyledPageWrapper } from 'common/styles';
import { getAddressDetails, replaceRoute } from 'common/utils';
import { IcEdit, IcCheck } from 'common/icons';
import { usePersistedSelectedCoordinates, usePersistedUserAddressId } from 'common/hooks';
import ListItem from 'common/components/ListItem';
import { URLS } from 'common/constants';

import { StyledLinkWrapper } from '../UserBookList/style';
import useHandleAddresses from '../../hooks/useHandleAddresses';
import ServiceConfigs from '../../../../service/config';

const List = dynamic(() => import('common/components/List'));

type AddressListProps = {
  showEditIcon?: boolean;
  showAddLink?: boolean;
  onBeforeSelectAddress?: () => void;
  onAfterSelectAddress?: (branchId: string) => void;
};

const AddressList: React.FC<AddressListProps> = ({
  showEditIcon = false,
  showAddLink = true,
  onAfterSelectAddress,
  onBeforeSelectAddress,
}) => {
  const router = useRouter();
  const { t } = useTranslation('address');
  const [persistedAddressID] = usePersistedUserAddressId();
  const [, setSelectedCoordinates, removePersistedCoordinates] = usePersistedSelectedCoordinates();
  const { addresses, isLoading, selectAddress, editAddress, addNewAddress } = useHandleAddresses({
    onAfterSelectAddress,
    onBeforeSelectAddress,
  });
  const useDeliveryzonesMs = ServiceConfigs?.useDeliveryzonesMs;

  useEffect(() => {
    if (!useDeliveryzonesMs) removePersistedCoordinates();
  }, []);

  const handleOnClick = address => {
    if (showEditIcon) {
      setSelectedCoordinates({ lat: parseFloat(address.lat), lng: parseFloat(address.lng) });
      editAddress(address);
    } else if (address?.covered) {
      if (useDeliveryzonesMs && (!address?.areaName || !address?.cityName)) {
        setSelectedCoordinates({ lat: parseFloat(address.lat), lng: parseFloat(address.lng) });
        replaceRoute({ pathname: URLS.EDIT_ADDRESS, query: { id: address?.id } });
      } else selectAddress(address);
    }
  };

  const renderItem = useCallback(
    address => (
      <>
        <ListItem
          key={address.id}
          dataTestId={`${address.id}_address_title`}
          {...getAddressDetails(address, t, router.locale)}
          disabled={!address?.covered && !showEditIcon}
          onClick={() => handleOnClick(address)}
          icon={(showEditIcon && <IcEdit />) || (address?.id === persistedAddressID ? <IcCheck /> : null)}
        />
      </>
    ),
    [router.locale, handleOnClick],
  );

  const isPrimaryButton = !addresses?.length;
  return (
    <StyledPageWrapper>
      {showAddLink && (
        <StyledLinkWrapper>
          <Button
            isFluid
            variant={isPrimaryButton ? 'primary' : 'tertiary'}
            text={t('addAddress')}
            testId="add_new_address_btn"
            onClick={addNewAddress}
          />
        </StyledLinkWrapper>
      )}
      <List data={(addresses as any) || []} renderItem={renderItem} isLoading={isLoading} />
    </StyledPageWrapper>
  );
};

export default AddressList;
