import { useState, useEffect } from 'react';
import { usePersistedCountryDetails } from './persistent';
import { useCountryDetails } from '../../service/hooks';
import { COUNTRY_CODES } from '../constants';

/**
 * A custom hook returns a boolean value whether the store is located in Kuwait city or not
 * @returns boolean
 */

const useIsKuwait = (): boolean => {
  const [isKuwait, setIsKuwait] = useState(false);
  const [persistedCountryDetails] = usePersistedCountryDetails();
  const { data: countryDetails } = useCountryDetails({ id: persistedCountryDetails?.id });

  useEffect(() => {
    if (countryDetails?.code === COUNTRY_CODES.KW) setIsKuwait(true);
  }, [countryDetails]);

  return isKuwait;
};

export default useIsKuwait;
