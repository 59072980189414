import React from 'react';
import { Typography } from '@zydalabs/zac-react';

import { StyledListItemWrapper, StyledDataWrapper, StyledTitle, DetailsSpan } from './style';

type ListItemType = React.FC<{
  dataTestId?: string;
  title: string;
  details: string;
  onClick: (item: any) => void;
  disabled?: boolean;
  icon?: React.ReactNode;
}>;

const ListItem: ListItemType = ({ dataTestId, title, details, onClick, disabled = false, icon }) => {
  const cursor = disabled ? 'default' : 'pointer';

  return (
    <StyledListItemWrapper onClick={onClick} disabled={disabled}>
      <StyledDataWrapper>
        <Typography testId={dataTestId} variant="heading16" cursor={cursor} isDisabled={disabled}>
          <StyledTitle disabled={disabled}>{title}</StyledTitle>
        </Typography>
        <Typography variant="body16" cursor={cursor} isDisabled={disabled}>
          <DetailsSpan disabled={disabled}> {details}</DetailsSpan>
        </Typography>
      </StyledDataWrapper>
      <div>{icon && icon}</div>
    </StyledListItemWrapper>
  );
};

export default ListItem;
