// These coordinates represent the center of the capitals of the specified countries.

export const KUWAIT_DEFAULT_COORDS = { lat: 29.276471108435235, lng: 47.89137743224225 };
export const EGYPT_DEFAULT_COORDS = { lat: 30.0444, lng: 31.2357 }; // Cairo
export const SAUDI_ARABIA_DEFAULT_COORDS = { lat: 24.7136, lng: 46.6753 }; // Riyadh
export const BAHRAIN_DEFAULT_COORDS = { lat: 26.2285, lng: 50.586 }; // Manama
export const UAE_DEFAULT_COORDS = { lat: 24.4539, lng: 54.3773 }; // Abu Dhabi
export const QATAR_DEFAULT_COORDS = { lat: 25.276987, lng: 51.520008 }; // Doha
export const OMAN_DEFAULT_COORDS = { lat: 23.6143, lng: 58.5453 }; // Muscat
export const JORDAN_DEFAULT_COORDS = { lat: 31.9539, lng: 35.9106 }; // Amman
export const FALLBACK_COORDS = { lat: -17.7134, lng: 178.065 }; // Fiji (Viti Levu Island)
