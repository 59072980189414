import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { Address } from 'service/types';
import { useAddressBook, useCountryDetails } from 'service/hooks';
import {
  useIsKuwait,
  usePersistedCountryDetails,
  usePersistedSelectedCoordinates,
  usePersistedSupportedDeliveryZone,
  usePersistedUserAddressId,
  useSelectAddressOrArea,
} from 'common/hooks';
import { FULFILLMENT_MODES, URLS } from 'common/constants';
import { changeRoute } from 'common/utils';
import { getClosestAddress } from 'modules/checkoutModule/utils';
import ServiceConfigs from '../../../service/config';
import { useFulfillmentSettings } from '../../../contexts';
import { getDefaultCoords } from '../utils/getDefaultCoords';

type useHandleAddressesType = ({
  onBeforeSelectAddress,
  onAfterSelectAddress,
}: {
  onBeforeSelectAddress?: () => void;
  onAfterSelectAddress?: (branchId: string) => void;
}) => {
  addresses: Array<Address>;
  isLoading: boolean;
  selectAddress: (address: Address) => void;
  editAddress: (address: Address) => void;
  addNewAddress: () => void;
};

const useHandleAddresses: useHandleAddressesType = ({ onBeforeSelectAddress, onAfterSelectAddress }) => {
  const router = useRouter();

  const { area, branch, orderMode } = useFulfillmentSettings();
  const [persistedUserAddressId] = usePersistedUserAddressId();
  const [selectedCoords, setPersistedSelectedCoordinates] = usePersistedSelectedCoordinates();
  const [persistedSupportedDzms] = usePersistedSupportedDeliveryZone();
  const [persistedCountryDetails] = usePersistedCountryDetails();
  const { data: countryDetails } = useCountryDetails({ id: persistedCountryDetails?.id });
  const isKuwait = useIsKuwait();

  const { data, isLoading } = useAddressBook({ areaId: area?.id });
  const { handleOnAddressSelected } = useSelectAddressOrArea(onAfterSelectAddress);

  const { addresses, recentlyUsedAddressPerArea } = data || {};
  const useDeliveryzonesMs = ServiceConfigs?.getUseDeliveryzonesMs();
  const isDelivery = orderMode === FULFILLMENT_MODES.DELIVERY;

  const onSelectAddress = address => {
    onBeforeSelectAddress?.();
    if (persistedUserAddressId === address.id) {
      onAfterSelectAddress?.(branch?.id);
    } else {
      handleOnAddressSelected(address);
    }
  };

  const addNewAddress = async () => {
    const defaultCoords = getDefaultCoords(countryDetails?.code);
    if (useDeliveryzonesMs) {
      navigator?.geolocation.getCurrentPosition(
        async ({ coords: { latitude, longitude } }) => {
          const coords = { lat: latitude, lng: longitude };
          await setPersistedSelectedCoordinates(coords);
          onBeforeSelectAddress?.();
          if (selectedCoords?.lat && selectedCoords?.lng && !isKuwait) changeRoute(URLS.NEW_ADDRESS);
          else changeRoute(URLS.ORDER_DELIVERY);
        },
        async () => {
          await setPersistedSelectedCoordinates(defaultCoords);
          onBeforeSelectAddress?.();
          if (selectedCoords?.lat && selectedCoords?.lng && !isKuwait) changeRoute(URLS.NEW_ADDRESS);
          else changeRoute(URLS.ORDER_DELIVERY);
        },
        { enableHighAccuracy: true, timeout: 10000, maximumAge: 300000 },
      );
    } else if (area?.id) {
      onBeforeSelectAddress?.();
      changeRoute(URLS.ADDRESS_MAP);
    } else {
      onBeforeSelectAddress?.();
      changeRoute(URLS.AREAS_LIST);
    }
  };

  const onEditAddress = address => {
    if (useDeliveryzonesMs) changeRoute({ pathname: URLS.EDIT_ADDRESS, query: { id: address?.id } });
    else changeRoute({ pathname: URLS.ADDRESS_MAP, query: { addressId: address?.id } });
  };

  const closestAddress = getClosestAddress(addresses, selectedCoords, orderMode, persistedSupportedDzms);

  useEffect(() => {
    if (!isDelivery) return;

    const isOnCheckout = router.pathname === URLS.CHECKOUT;
    if (!persistedUserAddressId)
      if (useDeliveryzonesMs) {
        if (closestAddress) handleOnAddressSelected(closestAddress);
      } else if (isOnCheckout && recentlyUsedAddressPerArea) handleOnAddressSelected(recentlyUsedAddressPerArea);
  }, [isDelivery, persistedUserAddressId, recentlyUsedAddressPerArea, router.asPath, closestAddress]);

  return { addresses, isLoading, selectAddress: onSelectAddress, editAddress: onEditAddress, addNewAddress };
};

export default useHandleAddresses;
